import { ISO8601Date } from '../types';

export const serviceVersion = '2024-11-22';

export type ServiceParams = {
	getCurrentTrackingDate: {
		userIdentifier: {
			type: 'user';
			value: string;
		};
		timezone: string;
	};
	testIncrementTrackingDate: {
		userId: string;
		timezone: string;
	};
};

export type ServiceResponses = {
	getCurrentTrackingDate: {
		trackingDate: ISO8601Date;
	};
	testIncrementTrackingDate: void;
};
