import { ISO8601Date, ISO8601DateTime } from '../types';

export const serviceVersion = '2024-11-22';

export type ServiceParams = {
	listUserActivity: {
		userIdentifier: {
			type: 'user';
			value: string;
		};
		timezone: string;
		includeResetActivity: boolean;
		order: 'oldest_first' | 'newest_first';
		filter: null | {
			types: null | FilterType[];
		};
	};
	recordActivityCompleted: {
		userId: string;
		timezone: string;
		params: {
			activityId: string;
			activityVariantId: string;
			context: ContextType;
			netCoins?: number;
		};
	};
	recordActivityLearningCompleted: {
		userId: string;
		timezone: string;
		params: {
			activityId: string;
		};
	};
	resetAllProgress: {
		userId: string;
	};
};

type ContextType = {
	choices: Record<string, string>;
} & ({ pelvicFloorExercise: PelvicFloorExerciseParams } | {});

export type ServiceResponses = {
	listUserActivity: Activity[];
	recordActivityCompleted: ActivityCompletedItem;
	recordActivityLearningCompleted: ActivityLearningCompletedItem;
	resetAllProgress: void;
};

export type PelvicFloorExerciseParams = {
	reps: number;
	duration: number;
	expectedReps: number;
	expectedDuration: number;
};

type Activity =
	| ActivityCompletedItem
	| ActivityLearningCompletedItem
	| ChallengeAcceptedItem
	| ChallengeDeclinedItem
	| StreakRestDaysArrangedItem;

type BaseItem = {
	id: string;
	type: string;
	createdAt: ISO8601DateTime;
	trackingDate: ISO8601Date;
	params: Record<string, unknown>;
};

export type ActivityCompletedItem = BaseItem & {
	type: 'activity_completed';
	// zero guarantees about anything inside params
	params: {
		activityId: string;
		activityVariantId: string | null;
		// context is an object, but we can't tell what's inside
		// strictly follow the backend docs for typing
		context: Record<string, unknown>;
		netCoins?: number;
	};
};

type ActivityLearningCompletedItem = BaseItem & {
	type: 'activity_learning_completed';
	params: {
		activityId: string;
	};
};

type ChallengeAcceptedItem = BaseItem & {
	type: 'challenge_accepted';
	params: {
		challengeId: string;
		completionsRequired: number;
		awardCoins: number;
		contributingActivityIds: string[];
	};
};

type ChallengeDeclinedItem = BaseItem & {
	type: 'challenge_declined';
	params: {
		challengeId: string;
	};
};

type StreakRestDaysArrangedItem = BaseItem & {
	type: 'streak_rest_days_arranged';
	params: {
		restDays: {
			trackingDate: string;
			netCoins: number;
		}[];
	};
};

type FilterType = Activity['type'];
